import React from "react";
import { Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { register } from 'swiper/element/bundle';
import './Landing.css';
import Kilang from '../../media/kilang.png';
import Customer from '../../media/Customer-List.png';
register();

const LandingPage = ()=>{
    const navigate = useNavigate();
    return(
        <div style={{display: 'flex', flexDirection:'column' , width: '100%', minHeighteight: '90vh', alignItems: 'center', justifyContent: 'flex-start',}}>
            <div style={{ display:'flex' ,width: '90%', height: '400px', marginTop: '40px', marginBottom: '40px'}}>
                <swiper-container 
                    className="swiper"
                    slides-per-view="1"
                    navigation="true"
                    pagination="true"
                    loop="true"
                >
                    <swiper-slide>
                        <div className="Slide1">
                            Slide 1
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="Slide2">
                            Slide 2
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="Slide3">
                            Slide 3
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>

            <Divider sx={{display: 'flex', width: '90%', backgroundColor: '#000', marginTop: '50px', marginBottom: '50px'}} />

            <div style={{paddingLeft: '100px', paddingRight: '100px', textAlign: 'justify', display: 'flex', flexDirection: 'row'}}>
                <img src={Kilang} alt="Gambar Kilang" width={300} height={300}/>
                <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    <Typography variant="h5">finding the right sytem integrator & supplier can be a challenging task. ultimately you must be able to trust the contractor and supplier whi will be helping you to make decisions about tecnology that will impact your company, your client, perhaps even your career. at poin industria your success is our success. with 90% of our bussiness garnered from referrals, we appreciate the value of doing the job right and maintaining a reputation for quality and services. our client "trust" us. they trust our bussiness ethics and he level of technical competency we provide</Typography>
                </div>
            </div>

            <Divider sx={{display: 'flex', width: '90%', backgroundColor: '#000', marginTop: '50px', marginBottom: '50px'}} />

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px', textAlign: 'justify'}}>
                <Typography variant="h1">About Us</Typography>
                <Typography variant="h5"><b>PT. POIN INDUSTRIA</b> is an engineering contractor in thru area of automation and control system for application in industries and factories by applying technology solutions. Experienced professional deliver project in your industry. Our core of industry focused expertise with the industry's most extensiveteam of experienced field engineer's ensure long-term return of your investment.</Typography>
            </div>

            <Divider sx={{display: 'flex', width: '90%', backgroundColor: '#000', marginTop: '50px', marginBottom: '50px'}} />

            <div style={{display: 'flex', flexDirection: 'column',width:'90%', alignItems: 'center', paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px'}}>
                <Typography variant="h3">OUR SERVICES</Typography>
                <div className="Stack">
                    <div className="Card">
                        <div className="subtitle">
                            <Typography>automation</Typography>
                            <Typography>&</Typography>
                            <Typography>electrical supplier</Typography>
                        </div>
                    </div>
                    <div className="Card">
                        <div className="subtitle">
                            <Typography>system integrator</Typography>
                        </div>
                    </div>
                    <div className="Card">
                        <div className="subtitle">
                            <Typography>system services</Typography>
                        </div>
                    </div>
                </div>
            </div>

            <Divider sx={{display: 'flex', width: '90%', backgroundColor: '#000', marginTop: '50px', marginBottom: '50px'}} />

            <div onClick={()=> navigate('/experience')} style={{display: 'flex', flexDirection: 'column',width:'90%', alignItems: 'center', paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px', cursor: 'pointer'}}>
                <Typography variant="h5">CUSTOMER</Typography>
                <Typography variant="h4" sx={{color: 'red'}}>LIST</Typography>
                <img src={Customer} alt='customer list'/>
            </div>

            <Divider sx={{display: 'flex', width: '90%', backgroundColor: '#000', marginTop: '50px', marginBottom: '50px'}} />

            <div style={{display: 'flex', flexDirection: 'column',width:'90%', alignItems: 'center', paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px'}}>
                <Typography variant="h3">OUR PRODUCT</Typography>
                <div className="Stack">
                    <div className="Card" onClick={() => navigate('/product/honeywell')}>
                        <div className="subtitle">
                            <Typography>Howeywell</Typography>
                        </div>
                    </div>
                    <div className="Card" onClick={() => navigate('/product/schneider')}>
                        <div className="subtitle">
                            <Typography>Schneider</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;