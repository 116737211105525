import React from "react";
import { ProjectGrid } from "../ProjectCard";

const ExperiencePage = ()=>{
    return(
        <div style={{display: 'flex', flexDirection:'column' , width: '100%', height: 'fit-content', alignItems: 'center'}}>
            <h1>Our Project</h1>
            <div style={{display: 'flex', flexDirection: 'row', width: '90%', flexWrap: 'wrap'}}>
                {ProjectGrid}
            </div>
            
        </div>
    );
};

export default ExperiencePage;