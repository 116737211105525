import React from "react";
import { Link } from "react-router-dom";

const ProductPage = ()=>{
    return(
        <div style={{display: 'flex', flexDirection:'column' , width: '100%'}}>
            <h1>Product </h1>
            <Link to='honeywell'>Honeywell</Link>
            <Link to='schneider'>Schneider</Link>
        </div>
    );
};

export default ProductPage;