import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import './App.css';
import LandingPage from './components/page/Landing';
import NavBar from './components/navbar';
import ExperiencePage from './components/page/Experience';
import ProductPage from './components/page/Product';
import ContactUs from './components/page/ContactUs';
import ServicePage from './components/page/Services';
import Honeywell from './components/page/HoneywellPage';
import Schneider from './components/page/SchneiderPage';
import BottomBar from './components/BottomBar';
import Logo from './media/logo.png';


function App() {

  return (
    <div className="App">
      <Router>
        <div className='top-header'>
          <img src={Logo} alt='logo' width='20%'/>
        </div>
        <NavBar/>
        <Routes>
          <Route exact path='/' element={<LandingPage/>} />
          <Route path='/experience' element={ <ExperiencePage/>} />
          <Route path='/product' element={<ProductPage/>}/>
          <Route path='/product/honeywell' element={<Honeywell/>}/>
          <Route path='/product/schneider' element={<Schneider/>}/>
          <Route path='/services' element={ <ServicePage/>} />
          <Route path='/contact-us' element={ <ContactUs/>} />
        </Routes>
        <BottomBar/>
      </Router>
      
    </div>
  );
}

export default App;
