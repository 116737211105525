import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import './navbar.css';
import ProductMenu from './ProductMenu';
import ServiceMenu from './ServiceMenu';


export default function NavBar() {
    const navigate = useNavigate();
        
    return (
        <div className="navbar">
            <Button onClick={()=>navigate("/")}><i class="fa fa-home"></i> <span/> <b>Home</b></Button>
            <ProductMenu/>
            <ServiceMenu/>
            <Button onClick={()=>navigate("/Experience")}>Experience</Button>
            <Button onClick={()=>navigate("/contact-us")}>Contact Us</Button>
        </div>
    );
};