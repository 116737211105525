import React from "react";

const ServicePage = ()=>{
    return(
        <div style={{display: 'flex', flexDirection:'column' , width: '100%', height: '90vh', alignItems: 'flex-start'}}>
            <h1>Services</h1>
        </div>
    );
};

export default ServicePage;