import React from "react";

const Honeywell = ()=>{
    return(
        <div style={{display: 'flex', flexDirection:'column' , width: '100%', height: '90vh', alignItems: 'flex-start'}}>
            <h1>Honeywell Product </h1>
            
        </div>
    );
};

export default Honeywell;