export const ProjectList = [
    {
        title: 'PT. Antam Pomala',
        date: 'Februari 2013',
        projectName: 'Turn Around',
        mainCont: 'PT. Timurindo Sukses Perkasa',
        desc: 'Transmiter Re-Calibration',
    },
    {
        title: 'PT. Molindo Raya Industrial',
        date: 'Maret  2013',
        projectName: 'DCS  Upgrade',
        mainCont: 'PT. Honeywell Indonesia',
        desc: 'Panel Modification, Installation, construction & Commisioning',
    },
    {
        title: 'PT. Total Indonesia',
        date: 'Apr,1 2013 - Juli, 2013',
        projectName: 'Ruby Tie In(Semi Offshore)',
        mainCont: 'PT. Jettec Indonesia',
        desc: 'Instrumentation Installation, Construction, Loop Check & Commisioning',
    },
    {
        title: 'PT. Pertamina RU IV Cilacap',
        date: '(Apr,I 2013)',
        projectName: 'DXS Upgrade Utility 1(Onshore)',
        mainCont: 'PT. Honeywell Indonesia',
        desc: 'Panel & Annuciator Instlallation, Construction & Commisioning',
    },
    {
        title: 'PT. Pertamina RU-III Palembang',
        date: 'Maret 2013 - April 2013',
        projectName: 'Turn Around Alkilasi Plant(Onshore)',
        mainCont: 'PT. Melanton Pratama',
        desc: 'Instrumentation Recalibrations, Installation, Loop Check',
    },
    {
        title: 'PT. Indorama',
        date: 'April 2013',
        projectName: 'Upgrade DCS',
        mainCont: 'PT. Honeywell Indonesia',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Kao Indonesia Chemical',
        date: 'Agustus 2013',
        projectName: 'Turn Around',
        mainCont: 'PT. Poin lndustria',
        desc: 'Cleaning & Re-calibration Control System',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: 'Juli 2014 - Jan 2015',
        projectName: 'Upgrade DCS pabrik 1',
        mainCont: 'PT. Honeywell Indonesia',
        desc: 'Panel Modification, Engineering & Commisioning',
    },
    {
        title: 'PT. Candra Asri',
        date: 'November 2014 - Mei 2015',
        projectName: 'Mimic Panel',
        mainCont: 'PT. Inti Karya Persada Teknik (IKPT)',
        desc: 'Panel Modification, Installation,Contruction, Commisioning',
    },
    {
        title: 'Sampoerna Pandaan',
        date: 'Maret 2015 - Agustus 2015',
        projectName: 'Building Automation System (BAS) AHLI ATB',
        mainCont: 'PT. Taiyo Sinar Raya Teknik',
        desc: 'Engineering, Installation, Constraction, Testing & Commisioning',
    },
    {
        title: 'Sampoerna Karawang ',
        date: 'Maret 2015 - Mei 2015',
        projectName: 'Building Automation System(BAS Warehouse)',
        mainCont: 'PT. Asahi Synchrotech Indonesia',
        desc: 'Engineering, Testing & Commisioning',
    },
    {
        title: 'PT. Trans Pasific Petrochemical Indotama TPPI)',
        date: '2016',
        projectName: 'Supply Transmitter',
        mainCont: '',
        desc: 'Supply Transmitter',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: '2018',
        projectName: 'Upgrade DCS ZA',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commisioning',
    },
    {
        title: 'PT. SAKA PANGKAH LIMITED',
        date: '2018',
        projectName: 'Provision of DCS Enhancement Service for Pangkah Onshore - Offshore Facility',
        mainCont: '',
        desc: 'Engineering, Installation, Construction, Testing & Commissioning',
    },
    {
        title: 'Medco E&P Natuna Ltd',
        date: '2018 - Now',
        projectName: 'Belanak N2 Generator Migration',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: '2018',
        projectName: 'Upgrade DCS Boiler',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'Saka Energi Indonesia',
        date: '2018 - Now',
        projectName: 'SAKA Maintenace Provision DCS Upgrade',
        mainCont: '',
        desc: 'Engineering & Commissioning',
    },
    {
        title: 'PT. Merch Sharp Dohme Pharma',
        date: '2019',
        projectName: 'Controller, Field Deviced, Maintenance Material',
        mainCont: '',
        desc: 'Controller, Field Deviced, Maintenance Material',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: '2019',
        projectName: 'Install Monitoring Ampere Meter',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: '2019',
        projectName: 'Perbaikan PHD DCS AMUREA 1',
        mainCont: '',
        desc: 'Maintenance Material',
    },
    {
        title: 'PT. TPC INDO PLASTIC AND CHEMICALS',
        date: '2019',
        projectName: 'Maintenance Spesial Project for Instrument MI EAMR',
        mainCont: '',
        desc: 'Maintenance Material',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: '2019',
        projectName: 'Supply Transmitter Honeywell',
        mainCont: '',
        desc: 'Supply Material',
    },
    {
        title: 'PT. Pertamina EP ASSET 2',
        date: '2020',
        projectName: 'Upgrading DCS di SPG PAGAR DEWA Prabumulih Field',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pupuk Kalimantan Timur',
        date: '2020',
        projectName: 'Jasa Perbaikan HPM Module DCS Honeywell TDC 3000 Plant Pabrik 1A',
        mainCont: '',
        desc: 'Maintenance Project',
    },
    {
        title: 'PT. Pupuk Kalimantan Timur',
        date: '2020',
        projectName: 'Jasa Konfigurasi Control DCS Honeywell TDC 3000 Plant Pabrik 1A',
        mainCont: '',
        desc: 'Maintenance Project',
    },
    {
        title: 'PT. Pertamina EP Asset 2',
        date: '2020',
        projectName: 'Upgrading DCS di SPG Merbau Prabumulih Field',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. TPC Indo Plastic and Chemicals',
        date: '2020',
        projectName: 'Upgrading DCS di SPG Merbau Prabumulih Field',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. TPC Indo Plastic and Chemicals',
        date: '2020',
        projectName: 'Maintenance special Project for Instrument MI - WDD Hardisk',
        mainCont: '',
        desc: 'Maintenance Material',
    },
    {
        title: 'PT.Pupuk Kalimantan Timur',
        date: '2020',
        projectName: 'Jasa Penambahan Remote HMI DCS Serta Parameter SEU Pabrik 1A',
        mainCont: '',
        desc: 'Maintenance Project',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: '2020',
        projectName: 'Upgrade B1102 FSC ke SM NH3 1A',
        mainCont: '',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Petrowell Energi',
        date: '2021',
        projectName: 'Supply Material & Service',
        mainCont: '',
        desc: 'Supply Material & Service',
    },
    {
        title: 'PT. Pupuk Kalimantan Timur',
        date: 'Feb 2021 — May 2021',
        projectName: 'Upgrading PLC Demin Ammonia P1A',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pupuk Kalimantan Timur',
        date: 'Mar 2021 — Aug 2021',
        projectName: 'Upgrading PLC Demineralization Plant 3',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pupuk Iskandar Muda',
        date: 'June 2021 — Oct 2021',
        projectName: 'Upgrading PLC & HMI MayCom E64-GC2001 PIM-2',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: 'July 2021 — Dec 2021',
        projectName: 'PHD Server P1A Replacement',
        mainCont: 'PT. Poin Industria',
        desc: 'PHD Server Replacement & Setting',
    },
    {
        title: 'PT. Petrokimia Gresik',
        date: 'Apr 2022 — Dec 2022',
        projectName: 'Upgrade PLC Conveyor Bagging & Demin 1 P1A',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Medco E&P Natuna',
        date: 'Apr 2022 — Jan 2023',
        projectName: 'North Belut Electrical Control Gateway Rectification',
        mainCont: 'PT. Poin Industria',
        desc: 'Engineering & Commissioning',
    },
    {
        title: 'PT. Pertamina EP',
        date: 'June 2022 — Oct 2022',
        projectName: 'Experion HS Upgrading Asset 4 Field Sukowati',
        mainCont: 'Elnusa F&K',
        desc: 'Engineering & Commisioning',
    },
    {
        title: 'PT. Medco E&P Natuna',
        date: 'July 2022 — present',
        projectName: 'FDM for Kerisi, North Belut & Belida',
        mainCont: 'PT. Poin Industria',
        desc: 'Engineering & Commisioning',
    },
    {
        title: 'PT. Pupuk Kalimantan Timur',
        date: 'Aug 2022 —present',
        projectName: 'SIS PLC Upgrading',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pertamina EP',
        date: 'Aug 2022 — Sept 2022',
        projectName: 'Shutdown Maintenance Asset 4 Field Sukowati',
        mainCont: 'Elnusa F&K',
        desc: 'Maintenance Project',
    },
    {
        title: 'PT. Pupuk Kalimantan Timur',
        date: 'Sept 2022 — Present',
        projectName: 'Upgrade PLC Chlorine Pabrik 4',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pertamina EP',
        date: 'Nov 2022 — Present',
        projectName: 'F&G SP Bambu Besar Expansion',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pertamina EP',
        date: 'Nov 2022 — Present',
        projectName: 'FSC SIL 3 Upgrading Pagardewa & Merbau',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
    {
        title: 'PT. Pertamina EP',
        date: 'Dec 2022 — Present',
        projectName: 'SIL 2 F&G SP Moeladi-Subang',
        mainCont: 'PT. Poin Industria',
        desc: 'Panel Modification, Engineering & Commissioning',
    },
];