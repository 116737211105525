import React from "react";
import {  Divider, Stack, Typography  } from '@mui/material';

import '../App.css'

const BottomBar = ()=> {
    return (
        <div className="bottom-bar">
            <Stack direction='row' spacing={2} sx={{
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography sx={{fontSize: '48px', fontWeight: '800'}}>FIND </Typography>
                <Typography sx={{fontSize: '48px', fontWeight: 800, color: 'blue'}}> US</Typography>
            </Stack> 
            <div className="icon-bottom">
                <i class='fa fa-facebook-square'   ></i>
                <i class='fa fa-whatsapp'></i>
                <i class='fa fa-instagram'></i>
                <i class='fa fa-linkedin-square'></i>
            </div>
            
            <Divider sx={{ backgroundColor: 'white', width: '90%', marginBottom: '10px'}}/>
            <Typography >Poin Industria © 2023 Copyright</Typography>
        </div>
    )
}

export default BottomBar;