import React from "react";
import { ProjectList } from './Project'
import { Typography } from "@mui/material";
import img from '../media/Slide1.jpg';
import './ProjectCard.css';

export const ProjectGrid = ProjectList.map(({  title, date, projectName, mainCont, desc },index) => {
    return (
        <div className="column-feature" key={index}>
            <div className="ExpCard">
                <img src={img} alt={title} />
            </div>
            <div className="sub-header">
                <Typography noWrap>{title}</Typography>
                <div className="description" style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                    <p>{date}</p>
                    <p>{projectName}</p>
                    <p>{mainCont}</p>
                    <p>{desc}</p>
                </div>
            </div>   
        </div>
    );
});