import * as React from 'react';
import { Button, Menu, MenuItem, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ServiceMenu(itemProp) {
    const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
    navigate('/services');
  };
  const handleClose2 = () => {
    setAnchorEl(null);
    navigate('/services');
  };
  const handleClose3 = () => {
    setAnchorEl(null);
    navigate('/services');
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <b>Service</b> <span/>
        <i class="fa fa-caret-down"></i>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <nav>
        <MenuItem onClick={handleClose1}>Service 1</MenuItem>
        <MenuItem onClick={handleClose2}>Service 2</MenuItem>
        <MenuItem onClick={handleClose3}>Service 3</MenuItem>
        </nav>
      </Menu>
    </div>
  );
}
